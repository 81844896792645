export const TOKEN_STORAGE_KEY = 'EdictDigitalToken';
export const LANGUAGE_STORAGE_KEY = 'EdictDigitalLanguage';
export const BASE_URL = 'https://yedek.okumaplatformu.com';
export const PRODUCT_TYPE = 'FRONTEND-MAIN';

export const EDICT_DASHBOARD_URL = 'https://edict.okumaplatformu.com';
export const ACCOUNTS_DASHBOARD_URL =
  'https://yedek-accounts.okumaplatformu.com';

export const CLIENT = 'MAIN';

export const SIGNIN_ROUTE = '/signin';
