import React, { useState } from 'react';

const GetLocalStorage = () => {
  useState(() => {
    const PERMITTED_DOMAIN = 'https://yedek-accounts.okumaplatformu.com';
    const messageReceived = (event) => {
      if (event.origin.startsWith(PERMITTED_DOMAIN)) {
        if (event.data) {
          const key = Object.keys(event.data)[0];
          const value = event.data[key];
          if (value !== '') {
            localStorage.setItem(key, value);
          } else {
            localStorage.removeItem(key);
          }
        }
      }
    };
    window.addEventListener('message', messageReceived);

    return () => {
      window.removeEventListener('message', messageReceived);
    };
  });
  return <></>;
};

export default GetLocalStorage;
