import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import PacmanLoader from 'react-spinners/PacmanLoader';
import { validateVcloudToken } from 'redux/actions/pages';

const Vcloud = ({ validate, redirectTo }) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('token');
  const clientid = searchParams.get('clientid');

  const history = useHistory();

  useEffect(() => {
    validate({ token, clientid });
    // 1. token ve client id varsa
    //   1.1. token ve client id'yi doğrulaması için backend'e gönder
    //   1.2. token ve client id doğrulandıysa
    //     1.2.1. auth reducer'a currentUser ve token bilgileri gönder
    //     1.2.2. header reducer değerlerini güncelle
    //     1.2.3. TOKEN'ı local storage'a kaydet
    //     1.2.4. kullanıcı tipine göre yönlendirme yap
    //       1.2.4.1. öğrenci ise /student'a yönlendir.
    //       1.2.4.2. öğretmen ise accounts'a yönlendir.
    //       1.2.4.3. diğerleri için / sayfasına yönlendir.
    //   1.3. token ve client id doğrulanamadıysa
    //     1.3.1. giriş işlemi başarısız oldu sayfasına yönlendir
    // 2 .token ve client id yoksa
    //   2.1. giriş işlemi başarısız oldu sayfasına yönlendir

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientid, token]);

  useEffect(() => {
    if (redirectTo && redirectTo !== '' && redirectTo !== '/vcloud') {
      history.push(redirectTo);
    }
    // eslint-disable-next-line
  }, [redirectTo]);

  return (
    <div className="loading whole-screen">
      <PacmanLoader color="#67BCEC" size={50} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  redirectTo: state.ui.redirectTo,
});

const mapDispatchToProps = {
  validate: validateVcloudToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(Vcloud);
